<template>
  <div class="pagination">
    <ul>
      <li><span class="active">1</span></li>
      <li><span>2</span></li>
      <li><span>3</span></li>
      <li><span>4</span></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Pagination'
}
</script>

<style lang="scss" scoped>
@import "~@/scss/vars.scss";
@import "~@/scss/mixins.scss";
@import "./style.scss";
</style>
