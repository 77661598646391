<template>
  <div class="page">
    <h2 class="main-title">№ {{getTitle}}</h2>
    <SubMenu/>
    <div class="header">
      <h3 class="sub-title">
        Документы
      </h3>
      <div class="button-box">
        <v-btn
          v-if="
          ($store.state.login.user.id == $store.state.smp.user || $store.state.login.mainDepartment || $store.state.login.administrator || $store.state.login.cekkmp)
          && !($store.state.smp.statusTree
            || $store.state.smp.statusFour
            || $store.state.smp.statusFive
            || $store.state.smp.statusEight
            || $store.state.smp.statusEleven
            ) || ($store.state.login.cekkmp && $store.state.smp.statusTwelve || $store.state.smp.statusEleven )"
          color="red"
          class="ma-2 white--text"
        >
          <label for="file">
            <v-icon
              left
              dark
            >
              mdi-plus
            </v-icon>
            Добавить
          </label>
        </v-btn>
        <input type="file" id="file" @change="uploadFile" ref="file" class="file">

        <v-btn
          color="white"
          class="ma-2 red--text"
          @click.prevent="downLoadAll()"
        >
        <v-icon
          left
          dark
        >
          mdi-cloud-upload
        </v-icon>
          Скачать все
        </v-btn>
      </div>
    </div>
    <div>
      <v-skeleton-loader
         v-if="!load"
          class="mx-auto"
          type="table-tbody"
        ></v-skeleton-loader>
        <table v-else class="table table-white">
          <thead>
            <tr>
              <th
                v-for="th in header"
                :key="th"
                class="text-center">
                {{th}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="td in getDocuments"
              :key="td.id"
            >
              <td class="text-center">{{ td.createdOn | moment('DD.MM.YYYY') }}</td>
              <td class="text-center">{{ td.creatorFullName }}</td>
              <td class="text-center">{{ td.name }}</td>
              <td class="text-center">
                  <v-icon color="blue" size="16px" @click.prevent="downloadItem(td.id, td.name)" >
                    mdi-file-download-outline
                  </v-icon>
                  <v-icon
                  size="16px"
                  color="red"
                  @click.prevent="removeFile = td.id;dialog = true">
                    mdi-trash-can-outline
                  </v-icon>
              </td>
            </tr>
          </tbody>
        </table>
    </div>
    <v-dialog
      v-model="dialog"
      width="500"
      >
      
      <v-card>
        <v-card-title class="text-h5">
          Вы действительно хотите удалить файл?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="white"
            class="ma-2 red--text"
            @click="dialog = false"
          >
            Нет
          </v-btn>
          <v-btn
            color="red"
            class="ma-2 white--text"
            @click.prevent="remove(removeFile)"
          >
            Да
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Pagination v-if="getDocuments.length > 20"/>
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination/Pagination.vue'
  import SubMenu from '@/components/SubMenu/SubMenu.vue'
  import {mapGetters, mapActions} from 'vuex'

  export default {
    name: 'Documents',
    components: {
      Pagination,
      SubMenu
    },
    data () {
      return {
        dialog: false,
        removeFile: null,
        header: [
          'Дата загрузка',
          'Автор документа. ФИО',
          'Наименование документа',
          'Действия',
        ],
        load: false,
        Images: null,
      }
    },
    computed : {
      ...mapGetters({
        getDocuments: 'documents/getDocuments',
        getTitle: 'smp/getTitle',
      }),
    },
    methods: {
      ...mapActions({
        documents: 'documents/documents',
        standart: 'smp/standart',
        getDocument: 'documents/getDocument',
        createDocument: 'documents/createDocument',
        removeDocuments: 'documents/removeDocuments'
      }),
      forceFileDownload(res, name){
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', name) //or any other extension
        document.body.appendChild(link)
        link.click()
      },
      downloadItem (id, name) {
        this.getDocument(id).then(res => {
          this.forceFileDownload(res, name)
        }).catch(console.error)
      },
      downLoadAll () {
        this.getDocuments.map(item => {
          this.downloadItem(item.id, item.name)
        })
      },
      uploadFile() {
        this.createDocument({StandartId: this.$route.params.id, File: this.$refs.file.files[0]}).then(() => {
          this.documents(this.$route.params.id)
        })
      },
      remove(id) {
        this.removeDocuments(id).then(() => {
          this.documents(this.$route.params.id)
          this.dialog = false
        })
      }
    },
    mounted() {
      this.standart(this.$route.params.id).then(() => this.load = true)
      this.documents(this.$route.params.id)
    }
  }
</script>

<style lang="scss" scoped>
  @import "~@/scss/vars.scss";
  @import "~@/scss/mixins.scss";
  @import "./style.scss";
</style>
